import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import OtherPageHeader from "../components/otherPageHeader.js";
import Toggle from "../components/toggle.js";



const FaqPage = () => {
  const content = useStaticQuery(graphql`
    query FaqsQuery {
      allFaqJson {
        edges {
          node {
            header
            faqs {
              title
              text
            }
          }
        }
      }
    }
  `)
  console.log({content})

  return (
    <>
      <Layout pageTitle="FAQ">
        <OtherPageHeader />
        <div className="main">
          <div className="section">
            <Container>
              <Row className="text-center">
                <Col>
                    <h2 className="text-primary">Our most frequently asked questions</h2>
                    <hr className="my-2 mx-5" />
                    <p className="lead">Look below to find answers to our most commonly asked questions.</p>
                    <p>Can’t find what you’re looking for?</p>
                    <p className="lead">
                      <Button tag={Link} to="/contact" color="primary">Contact Us!</Button>
                    </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-tight-top">
            <Container>
              <Row>
                {content.allFaqJson.edges.map(edge => (
                  <Col md="6">
                    <Toggle
                      key={edge.id}
                      header={edge.node.header}
                      faqs={edge.node.faqs}
                    />
                  </Col>
                ))}
              </Row>
              {/* <Row>
                <Col md="6">
                  <Toggle
                    key="1"
                    header="Covenants"
                    title="Q: Where can I find the local covenants?"
                    text={[ "A: You can download a searchable PDF version of the Elkhorn Highland Ridge Covenants:",
                      <Button tag={Link} to="/covenants" className="btn-link pl-1" color="primary" size="sm">
                        HERE
                      </Button>
                    ]}
                  />
                  <Toggle
                    key="2"
                    header="Trash/Recycling"
                    title="Q: When do trash and recycling get picked up?"
                    text={["A: Trash day is Thursday and is picked up weekly.  Recyclables are picked up every other week.  Find more information:",
                      <Button tag={Link}
                              className="btn-link pl-1"
                              color="primary"
                              size="sm"
                              href="https://wasteline.org/"
                              key="wasteline"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="wasteline">
                        HERE
                      </Button>
                    ]}
                  />
                </Col>
                <Col md="6">
                  <Toggle
                    key="3"
                    header="Services"
                    title="Q: Which organizations handle local services?"
                    text={[ "A: ",
                      <ul>
                        <li>Landscaping: Elkhorn Highland Ridge HOA</li>
                        <li>Snow Removal: City of Omaha</li>
                        <li>Street and street sign repair: City of Omaha</li>
                        <li>Street light repair: OPPD</li>
                        <li>Fire/Police/EMS: City of Omaha</li>
                      </ul>
                    ]}
                  />
                  <Toggle
                    key="4"
                    header="Annual Dues"
                    title="Q: Are dues mandatory, how much are they, and what do they pay for?"
                    text={[ "A: You can learn more about the annual dues:",
                      <Button tag={Link} to="/dues" className="btn-link pl-1" color="primary" size="sm">
                        HERE
                      </Button>
                    ]}
                  />
                </Col>
                <Col md="6">
                  <Toggle
                    key="5"
                    header="Volunteering"
                    title="Q: How can I get involved?"
                    text={[ "A: As volunteer opportunities come up throughout the year we'll reach out via our email list.  Sign up:",
                      <Button tag={Link} to="/contact" className="btn-link pl-1" color="primary" size="sm">
                        HERE
                      </Button>
                    ]}
                  />
                </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default FaqPage
