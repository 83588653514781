import React, { useState } from 'react';
//import { container } from './site.scss'

// reactstrap components
import {
  Collapse,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";

const Toggle = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
      // <Card className="no-transition" style={{ minWidth: '100%' }}>
      <Card style={{ minWidth: '100%' }}>
          <CardHeader className="h5" style={{ cursor: 'pointer' }} onClick={toggle}>{props.header}</CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              {props.faqs.map(edge => (
                <>
                  <CardTitle className="text-primary lead">{edge.title}</CardTitle>
                  {/* <CardText>{edge.text}</CardText> */}
                  {/* Support for HTML in card text */}
                  <CardText dangerouslySetInnerHTML={{ __html: edge.text }} />
                </>
                ))}
            </CardBody>
          </Collapse>
      </Card>  
  );
}

export default Toggle;